import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FixedSizeList as List } from 'react-window';
import styles from './styled-select.module.scss';
import styleJs from './style';

const MenuList = ({
  options, children, maxHeight, getValue,
}) => {
  const height = 38;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  return <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
      {({ index, style }) => <div style={{ ...style, textOverflow: 'ellipsis', backgroundColor: '#FFFFFF' }}>{children[index]}</div>}
    </List>;
};

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  isCreatable = false,
  customMenuList = false,
  formatCreateLabel = null,
  ...props
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => {
        console.log('controller focus');
      }}
      render={({
        field,
      }) => (
          <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            {!isCreatable
              ? <>
                <Select
                  {...field}
                  {...props}
                  components={ customMenuList ? { MenuList } : null }
                  className={`${styles.select}`}
                  placeholder={placeholder}
                  styles={styleJs}
                />
              </>
              : <>
                <CreatableSelect
                  {...field}
                  {...props}
                  className={`${styles.select}`}
                  formatCreateLabel={formatCreateLabel}
                  placeholder={placeholder}
                  styles={styleJs}
                />
              </>
            }
          </div>
      )}
    />
);

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
