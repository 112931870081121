import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Panel.module.scss';

const Stat = ({ data }) => <div className={styles.stat}>
  <div className={styles.bar}>
    <div
      className={`${styles.value} ${styles[data.type]}`}
      style={{ width: `${(data.value.length * 100) / data.max}%` }}
    >
    </div>
  </div>
  <div className={styles.infos}>
    <p>{data.label}</p>
    <p>{data.value.length}</p>
  </div>
</div>;

export default function Panel() {
  const { panel } = useSelector((state) => state.geography);
  const list = panel?.nodes?.find((n) => n.type === panel.type);
  function getUrl(type, id) {
    if (type === 'study') {
      return `${process.env.REACT_APP_CLINOCTA_URL}/edit-study/${id}/`;
    }
    if (type === 'project') {
      return `${process.env.REACT_APP_OCTADMIN_URL}/project/${id}/`;
    }
    if (type === 'doctor') {
      return `${process.env.REACT_APP_OCTADMIN_URL}/user/${id}/`;
    }
    if (type === 'drug') {
      return `${process.env.REACT_APP_OCTADMIN_URL}/product/${id}/`;
    }
    return null;
  }
  return (
    <div className={`${styles.container} ${panel ? styles.active : ''}`}>
      <section>
        <label>{panel?.center ? 'service' : 'Centre'}</label>
        <a href={`${process.env.REACT_APP_OCTADMIN_URL}/center/${panel?.centerId}?form=0`} target='_blank' rel="noreferrer">
          <h3>{panel?.name}</h3>
        </a>
        <h5>{panel?.center && `${panel?.center} -`} {panel?.city}</h5>
        {!list && panel?.nodes.length > 0 && <div className={styles.stats}>
          {panel?.nodes
            .sort((a, b) => b.value.length - a.value.length)
            .map((n) => <Stat key={n.type} data={n} />)}
        </div>}
      </section>
      {!list && panel?.services?.length > 0 && <section>
        <label>Services</label>
        <div className={`${styles.links} ${styles.service}`}>
          {panel.services.map((s) => <p key={s._id}>
            <a href={`${process.env.REACT_APP_OCTADMIN_URL}/center/${panel?.centerId}?form=0`} target='_blank' rel="noreferrer">{s.name}</a>
          </p>)}
        </div>
      </section>}
      {list?.value?.length > 0 && <section>
        <label>{list.label}</label>
        <div className={`${styles.links} ${styles[panel.type]}`}>
          {list.value.map((s) => <p key={s._id}>
            {getUrl(panel.type, s._id)
              ? <a href={getUrl(panel.type, s._id)} target='_blank' rel="noreferrer">{s.name}</a>
              : <span>{s?.name}</span>
            }
          </p>)}
        </div>
      </section>}
    </div>
  );
}
