import {
  GET_COMPARATOR_LISTS,
  RESET_COMPARATOR_TYPE,
  POST_COMPARATOR,
  ERROR_COMPARATOR,
  MESSAGE_COMPARATOR,
  SET_COMPARATOR_QUERY,
  POST_COMPARATOR_OPTIONS,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  lists: null,
  comparatorList: null,
  comparatorOptions: null,
  query: '?type=drug',
  changeAt: new Date(),
};

export default function comparatorReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_COMPARATOR_TYPE:
      return { ...state, comparatorList: null, comparatorOptions: null };
    case SET_COMPARATOR_QUERY:
      return { ...state, query: action.payload };
    case POST_COMPARATOR:
      return { ...state, comparatorList: action.payload, changeAt: new Date() };
    case POST_COMPARATOR_OPTIONS:
      return { ...state, comparatorOptions: action.payload };
    case GET_COMPARATOR_LISTS:
      return { ...state, lists: action.payload };
    case ERROR_COMPARATOR:
      return { ...state, error: action.payload };
    case MESSAGE_COMPARATOR:
      return { ...state, message: action.payload };
    default:
      return { ...state };
  }
}
