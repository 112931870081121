import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import logo from '../../assets/images/logo.svg';
import styles from './Nav.module.scss';

export default function Nav({ logout }) {
  const { userAuth, error } = useSelector((state) => state.auth);
  const comparatorQuery = useSelector((state) => state.comparator.query);
  // const treeQuery = useSelector((state) => state.tree.query);
  const timelineQuery = useSelector((state) => state.timeline.query);
  const navigate = useNavigate();
  const location = useLocation();

  function getIndicatorStyle() {
    if (location.pathname === '/comparator') {
      return {
        opacity: 1,
        transform: 'translateX(0px)',
      };
    }
    if (location.pathname === '/timeline') {
      return {
        opacity: 1,
        transform: `translateX(${160}px)`,
      };
    }
    if (location.pathname === '/geographie') {
      return {
        opacity: 1,
        transform: `translateX(${160 * 2}px)`,
      };
    }
    if (location.pathname === '/tree') {
      return {
        opacity: 1,
        transform: `translateX(${160 * 3}px)`,
      };
    }
    return {
      opacity: 0,
      transform: 'translateX(0px)',
    };
  }

  useEffect(() => {
    if (userAuth && error === 401) {
      logout();
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <button
        onClick={() => navigate(`/comparator${comparatorQuery}`)}
        className={`${styles.logo} ${userAuth ? styles.active : ''}`}
      >
        <img src={logo} alt={'logo'} />
        <h2>OCTAVIZ</h2>
      </button>
      <div
        className={`${styles.title} ${userAuth ? styles.active : ''}`}
      >
        <h1>VISUALISATION</h1>
      </div>
      <button
        onClick={() => logout()}
        className={`${styles.logout} ${userAuth ? styles.active : ''}`}
      >
        <MdLogout size={20}/>
        {/* <p>Déconnexion</p> */}
      </button>
      <div
        className={`${styles.nav} ${userAuth ? styles.active : ''}`}
      >
        <nav>
          <div
            className={styles.indicator}
            style={getIndicatorStyle()}
          />
          <NavLink
            to={`/comparator${comparatorQuery}`}
            className={(state) => `${styles.link} ${state.isActive ? ` ${styles.selected}` : ''}`}
          >
            COMPARATIF
          </NavLink>
          <NavLink
            to={`/timeline${timelineQuery}`}
            className={(state) => `${styles.link} ${state.isActive ? ` ${styles.selected}` : ''}`}
          >
            TIMELINE
          </NavLink>
          <NavLink
            to="/geography"
            className={(state) => `${styles.link} ${state.isActive ? ` ${styles.selected}` : ''}`}
          >
            GÉOGRAPHIE
          </NavLink>
          <NavLink
            to={'/network'}
            className={(state) => `${styles.link} ${state.isActive ? ` ${styles.selected}` : ''}`}
          >
            CERCLE
          </NavLink>
        </nav>
      </div>
    </div>
  );
}
