import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import useWindowSize from './hook/useWindowSize';
import MobilePage from '../src/pages/Mobile/MobilePage';
import GlobalRouter from './pages/GlobalRouter';

function App() {
  const { width } = useWindowSize();
  return (
    <div className="app-container">
      { width >= 1024
        ? <Router>
        <GlobalRouter />
      </Router>
        : <MobilePage/>
      }
    </div>
  );
}

export default App;
