import React, { useEffect, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import {
  Routes, Route, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../actions/authActions';
import { setVhUnit } from '../utils/utils';

// import Nav from '../components/Nav/Nav';
// import Toaster from '../lib/Toaster/Toaster';

import Index from '../pages/Auth/SwitchIndex';
import Login from './Auth/Login';
import Comparator from './Comparator/Comparator';
import Nav from '../components/Nav/Nav';
import Page404 from './Page404/Page404';
import { ERROR_AUTH, USER_AUTH } from '../actions/types';
// import Tree from './Tree/Tree';
import Timeline from './Timeline/Timeline';
import Geography from './Geography/Geography';
import { TOOL_ID } from '../actions';
import Network from '../components/Network/Network/Network';

export default function GlobalRouter() {
  const { error, userAuth } = useSelector((state) => state.auth);
  const isAuth = userAuth?.tools?.find((p) => p.tool === TOOL_ID && p.role === 'Admin');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = pathname === '/login' || pathname === '/';
  const dispatch = useDispatch();
  const location = useLocation();
  const transitions = useTransition(location.pathname, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)' },
  });
  // const { toast } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function logout() {
    navigate('/login');
    localStorage.removeItem('token');
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
    dispatch({
      type: USER_AUTH,
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
    }
    setVhUnit();
  }, []);

  useEffect(() => {
    if (!isLogin && error === 401) {
      logout();
    }
  }, [error]);

  return (
    <>
      {isAuth && <Nav logout={logout} />}
      {transitions((props, item, key) => (
        <animated.div key={key} style={props}>
          <Routes location={item}>
            <Route exact path='/' element={<Index />}/>
            <Route exact path='/login' element={<Login />}/>
            {isAuth
            && <>
                <Route exact path='/comparator' element={<Comparator />}/>
                <Route exact path='/geography' element={<Geography />}/>
                {/* <Route exact path='/tree' element={<Tree />}/> */}
                <Route exact path='/network' element={<Network />}/>
                <Route exact path='/timeline' element={<Timeline />}/>
              </>
            }
            <Route path='*' element={<Page404 />}/>
          </Routes>
        </animated.div>
      ))}
      {/* <Toaster toast={toast} dispatch={dispatch}/> */}
    </>
  );
}
