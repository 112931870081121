import { getData, postData } from './index';

import {
  ERROR_COMPARATOR,
  GET_COMPARATOR_LISTS,
  POST_COMPARATOR,
  POST_COMPARATOR_OPTIONS,
} from './types';

export async function getComparatorListsAction(dispatch) {
  const url = '/dataviz/octaviz/compare';
  let compareList;
  await getData(ERROR_COMPARATOR, url, dispatch, true).then((response) => {
    compareList = response.objects;
  });
  dispatch({
    type: GET_COMPARATOR_LISTS,
    payload: compareList,
  });
}

export async function postComparatorOptionsAction(dispatch, obj) {
  const url = '/dataviz/octaviz/compare';
  let options;
  await postData(POST_COMPARATOR_OPTIONS, ERROR_COMPARATOR, url, dispatch, obj, true)
    .then((response) => {
      options = response;
    });
  options.items = options.items.map(
    (item) => ({ value: item._id, label: item.name }),
  );
  dispatch({
    type: POST_COMPARATOR_OPTIONS,
    payload: options,
  });
}

export async function postComparatorAction(dispatch, obj) {
  const url = '/dataviz/octaviz/compare/items';
  let items;
  await postData(
    POST_COMPARATOR, ERROR_COMPARATOR, url, dispatch, obj, true,
  ).then((response) => {
    items = response.items;
  });
  dispatch({
    type: POST_COMPARATOR,
    payload: items,
  });
}
