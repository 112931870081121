// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const SET_TOAST = 'SET_TOAST';
// COMPARATOR ACTIONS
export const GET_COMPARATOR_LISTS = 'GET_COMPARATOR_LISTS';
export const ERROR_COMPARATOR = 'ERROR_COMPARATOR';
export const MESSAGE_COMPARATOR = 'MESSAGE_COMPARATOR';
export const SET_COMPARATOR_QUERY = 'SET_COMPARATOR_QUERY';
export const POST_COMPARATOR = 'POST_COMPARATOR';
export const POST_COMPARATOR_OPTIONS = 'POST_COMPARATOR_OPTIONS';
export const RESET_COMPARATOR_TYPE = 'RESET_COMPARATOR_TYPE';
// TREE ACTIONS
export const GET_TREE_LISTS = 'GET_TREE_LISTS';
export const ERROR_TREE = 'ERROR_TREE';
export const MESSAGE_TREE = 'MESSAGE_TREE';
export const SET_TREE_QUERY = 'SET_TREE_QUERY';
export const POST_TREE = 'POST_TREE';
export const POST_TREE_OPTIONS = 'POST_TREE_OPTIONS';
export const RESET_TREE_TYPE = 'RESET_TREE_TYPE';
// TIMELINE ACTIONS
export const GET_TIMELINE_TYPES = 'GET_TIMELINE_TYPES';
export const ERROR_TIMELINE = 'ERROR_TIMELINE';
export const MESSAGE_TIMELINE = 'MESSAGE_TIMELINE';
export const SET_TIMELINE_QUERY = 'SET_TIMELINE_QUERY';
export const POST_TIMELINE = 'POST_TIMELINE';
// GEOGRAPHY ACTIONS
export const ERROR_GEOGRAPHY = 'ERROR_GEOGRAPHY';
export const POST_GEOGRAPHY = 'POST_GEOGRAPHY';
export const GET_GEOGRAPHY_OPTIONS = 'GET_GEOGRAPHY_OPTIONS';
export const MESSAGE_GEOGRAPHY = 'MESSAGE_GEOGRAPHY';
export const SET_GEOGRAPHY_QUERY = 'SET_GEOGRAPHY_QUERY';
export const SET_GEOGRAPHY_PANEL = 'SET_GEOGRAPHY_PANEL';
export const SET_GEOGRAPHY_FILTERS = 'SET_GEOGRAPHY_FILTERS';
// NETWORK ACTIONS
export const ERROR_NETWORK = 'ERROR_NETWORK';
export const GET_NETWORK = 'GET_NETWORK';
