import React, { useRef } from 'react';
import ComparatorList from '../../components/Comparator/ComparatorList/ComparatorList';
import Filters from '../../components/Comparator/Filters/Filters';
import styles from './Comparator.module.scss';

export default function Comparator() {
  const filtersRef = useRef();
  return (
    <div className={styles.container}>
      <Filters ref={filtersRef}/>
      <ComparatorList
        handleChangeQuery={filtersRef && filtersRef?.current?.handleChangeQuery}
        filters={filtersRef && filtersRef?.current?.filters}
      />
    </div>
  );
}
