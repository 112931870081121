import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { extent, scaleLinear } from 'd3';
import useSize from '@react-hook/size';
import { useDispatch, useSelector } from 'react-redux';
import martiniqueMap from '../../components/Geography/Map/geojson/communes-martinique.json';
import guyaneMap from '../../components/Geography/Map/geojson/communes-guyane.json';
import guadeloupeMap from '../../components/Geography/Map/geojson/communes-guadeloupe.json';
import franceMap from '../../components/Geography/Map/geojson/france.json';

import iconFrance from '../../assets/images/france.svg';
import iconMartinique from '../../assets/images/martinique.svg';
import iconGuyane from '../../assets/images/guyane.svg';
import iconGuadeloupe from '../../assets/images/guadeloupe.svg';

import { getGeographyOptionsAction } from '../../actions/geographyActions';

import Map from '../../components/Geography/Map/Map';

import styles from './Geography.module.scss';
import { SET_GEOGRAPHY_PANEL } from '../../actions/types';
import Panel from '../../components/Geography/Panel/Panel';
import Filters from '../../components/Geography/Filters/Filters';

export default function Geography() {
  const dispatch = useDispatch();
  const { geography } = useSelector((state) => state.geography);
  const mapOptions = [
    {
      label: 'France',
      geojson: franceMap,
      icon: iconFrance,
      scale: 2300,
    },
    {
      label: 'Martinique',
      geojson: martiniqueMap,
      icon: iconMartinique,
      scale: 55000,
    },
    {
      label: 'Guyane',
      geojson: guyaneMap,
      icon: iconGuyane,
      scale: 7300,
    },
    {
      label: 'Guadeloupe',
      geojson: guadeloupeMap,
      icon: iconGuadeloupe,
      scale: 45300,
    },
  ];
  const mapRef = useRef();
  const [width, height] = useSize(mapRef);

  const [selectedMap, setSelectedMap] = useState(mapOptions[0]);
  const [mapData, setMapData] = useState();

  const initData = useCallback(() => {
    getGeographyOptionsAction(dispatch);
  }, [dispatch]);

  function handleChangePanel(data) {
    dispatch({
      type: SET_GEOGRAPHY_PANEL,
      payload: data,
    });
  }

  useEffect(() => {
    initData();
    return () => {
      setMapData();
    };
  }, []);

  useEffect(() => {
    let data = [];
    if (geography?.length > 0) {
      data = geography.map((c) => {
        const services = c.services.map((s) => {
          const extentServiceNodes = extent([
            s?.studies?.length || 0,
            s?.drugs?.length || 0,
            s?.projects?.length || 0,
            s?.doctors?.length || 0,
          ].filter((v) => v > 0));

          const nodeServiceScale = scaleLinear()
            .domain(extentServiceNodes)
            .range([40, 80]);

          const max = extentServiceNodes[1];

          const serviceNodes = [
            {
              label: 'Etudes',
              type: 'study',
              size: s?.studies?.length ? nodeServiceScale(s.studies.length) : 0,
              value: s?.studies,
              max,
            },
            {
              label: 'Produits',
              type: 'drug',
              size: nodeServiceScale(s.drugs?.length),
              value: s.drugs,
              max,
            },
            {
              label: 'Projets',
              type: 'project',
              size: nodeServiceScale(s.projects?.length),
              value: s.projects,
              max,
            },
            {
              label: 'Médecins',
              type: 'doctor',
              size: nodeServiceScale(s.doctors?.length),
              value: s.doctors,
              max,
            },
          ];
          return {
            id: s._id,
            size: 6,
            color: '#A490D7',
            name: s.name,
            type: 'service',
            centerId: c._id,
            nodes: serviceNodes?.filter((n) => n.value && n.value.length > 0),
          };
        });

        const extentCenterNodes = extent([
          c.studies.length,
          c.drugs.length,
          c.projects.length,
          c.doctors.length,
        ].filter((v) => v > 0));

        const nodeScale = scaleLinear()
          .domain(extentCenterNodes)
          .range([40, 80]);

        const max = extentCenterNodes[1];
        const centerNodes = [
          {
            label: 'Etudes',
            type: 'study',
            size: nodeScale(c.studies.length),
            value: c.studies,
            max,
          },
          {
            label: 'Produits',
            type: 'drug',
            size: nodeScale(c.drugs.length),
            value: c.drugs,
            max,
          },
          {
            label: 'Projets',
            type: 'project',
            size: nodeScale(c.projects.length),
            value: c.projects,
            max,
          },
          {
            label: 'Médecins',
            type: 'doctor',
            size: nodeScale(c.doctors.length),
            value: c.doctors,
            max,
          },
        ];
        const center = {
          id: c._id,
          lat: c.location.lat,
          lon: c.location.lon,
          city: c.location.city,
          centerId: c._id,
          name: c.name,
          size: 6,
          services,
          color: '#4F23B1',
          type: 'center',
          nodes: centerNodes.filter((n) => n.value.length > 0),
        };
        return center;
      });
    }
    setMapData(data);
    // eslint-disable-next-line
  },[geography , selectedMap , width]);

  return (
    <div ref={mapRef} className={`page-container ${styles.container}`}>
      <Filters />
      <Panel />
      {mapData?.length > 0 && selectedMap?.geojson
        && <div className={`${styles.map}`}>
          {width && <Map
            data={mapData}
            mapScale={selectedMap.scale}
            mapKey={selectedMap.label}
            geojson={selectedMap.geojson}
            mapWidth={width}
            mapHeight={height}
            handleChangePanel={handleChangePanel}
          />}
        </div>
        }
      <div className={styles['select-map']}>
        <div className={styles['btn-container']}>
          {mapOptions.map((map, i) => (
            <button
              key={`map${ i}`}
              className={selectedMap.label !== map.label ? styles['not-active'] : ''}
              onClick={() => setSelectedMap(map)}>
              <img src={map.icon} className={styles[map.label]} alt={map.label}/>
              <p>{map.label}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
