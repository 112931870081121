import React, {
  useEffect, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
// import { isSameDay } from 'date-fns';
import uniqid from 'uniqid';
// import GroupedPlanning from '../../components/GroupedPlanning/GroupedPlanning';

import styles from './TimelineViz.module.scss';
import TimelineRow from './TimelineRow/TimelineRow';
import usePrevious from '../../../hook/usePrevious';

export default function TimelineViz() {
  const { timelineList, timelineTypes } = useSelector((state) => state.timeline);
  const today = new Date();
  const minYearWidth = 850;
  const maxYearWidth = minYearWidth * 12;
  const monthRef = useRef();
  const contentRef = useRef();
  const timelineRef = useRef();
  const timerRef = useRef(null);

  const [yearWidth, setYearWidth] = useState(minYearWidth * 3);
  const [timelineData, setTimelineData] = useState();
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
  const [dates, setDates] = useState();

  const previousYearWidth = usePrevious(yearWidth);
  const previousScrollPos = usePrevious(scrollPos);

  function distBeetweenDates(first, second) {
    const numberOfDays = Math.round((second - first) / (1000 * 60 * 60 * 24));
    return numberOfDays * (yearWidth / 365);
  }

  function getMonth(d) {
    let isActiveMonth = false;
    const month = new Date(d);
    if (today.getFullYear() === month.getFullYear() && today.getMonth() === month.getMonth()) {
      isActiveMonth = true;
    }
    return ({
      className: `${styles.month} ${isActiveMonth ? styles['is-active'] : ''}`,
      isActive: isActiveMonth,
    });
  }

  function handleScroll(e) {
    setScrollPos({
      x: e.target.scrollLeft,
      y: e.target.scrollTop,
    });
  }

  function goToCurrentMonth() {
    if (monthRef.current) {
      contentRef.current.scrollTo({
        left: monthRef.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }

  function handleChangeYearWidth(newSize) {
    setYearWidth(newSize);
  }

  useEffect(() => () => clearTimeout(timerRef.current), []);

  useEffect(() => {
    if (timelineList?.length > 0 && timelineTypes?.length) {
      const filtered = [...timelineList];
      let startDate = new Date();
      let endDate = new Date();
      filtered?.forEach((t) => {
        const keys = ['startDate', 'endDate', 'date'];
        keys.forEach((k) => {
          if (t[k] && new Date(t[k]) < startDate) {
            startDate = new Date(t[k]);
          }
          if (t[k] && new Date(t[k]) > endDate) {
            endDate = new Date(t[k]);
          }
        });
      });
      const datesList = [];
      let date = new Date(startDate).setDate(1);
      do {
        datesList.push(new Date(date).toUTCString());
        date = new Date(date).setMonth(new Date(date).getMonth() + 1);
      } while (date < new Date(endDate).setMonth(new Date(endDate).getMonth() + 1));
      setDates(datesList);
      let stepsList = [];
      const steps = filtered.map((d) => {
        const step = { ...d, stepId: uniqid() };
        step.color = timelineTypes?.find((type) => type.value === d.type)?.color;
        if (step?.date && step?.date !== '') {
          const offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(step.date).setHours(0),
          );
          step.sortDate = new Date(step.date);
          step.styles = {
            left: offset, width: 20, top: 30, height: 20,
          };
          if (step.styles) {
            return step;
          }
        }
        if (step?.startDate !== '' && step?.endDate !== '') {
          const offset = distBeetweenDates(
            new Date(startDate),
            new Date(step.startDate).setHours(0),
          );
          const width = distBeetweenDates(
            new Date(step.startDate),
            new Date(step.endDate),
          );

          step.sortDate = new Date(step.startDate);
          step.styles = {
            left: offset || 0, width, top: 30, height: 20,
          };
          if (step.styles) {
            return step;
          }
        }
        return null;
      });

      stepsList = [...stepsList, ...steps];
      stepsList = stepsList.sort((a, b) => a.styles.left - b.styles.left);

      const groups = [];
      stepsList.forEach((s) => {
        const item = { ...s };
        let index = 0;
        let isInsert = false;
        while (!isInsert) {
          if (!groups[index]) {
            groups[index] = [];
          }
          const notInGroup = groups[index].find(
            (a) => a.styles.left < s.styles.left + s.styles.width
              && a.styles.left + a.styles.width > s.styles.left,
          );
          if (!notInGroup) {
            groups[index].push(item);
            isInsert = true;
          } else {
            index++;
          }
        }
        setTimelineData(groups);
      });
    }
    return null;
  }, [timelineList, yearWidth, timelineTypes]);

  useEffect(() => {
    if (scrollPos?.x === 0) {
      goToCurrentMonth();
    }
  }, [timelineData, monthRef.current]);

  useEffect(() => {
    if (previousYearWidth !== yearWidth && previousScrollPos?.x) {
      timerRef.current = setTimeout(() => contentRef.current.scrollTo({
        left: (previousScrollPos.x / previousYearWidth) * yearWidth,
        behavior: 'smooth',
      }), 100);
    }
  }, [yearWidth]);

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <button
          onClick={() => goToCurrentMonth()}
        >
          voir le mois en cours
        </button>
        <button
          className={`${styles.btn} ${yearWidth === minYearWidth ? styles.active : ''}`}
          onClick={() => handleChangeYearWidth(minYearWidth)}
        >
          VUE ANNée
        </button>
        <button
          className={`${styles.btn} ${yearWidth === maxYearWidth ? styles.active : ''}`}
          onClick={() => handleChangeYearWidth(maxYearWidth)}
        >
          VUE MOIS
        </button>
        <div className={styles.slider}>
          <Slider
            min={minYearWidth}
            max={maxYearWidth}
            onAfterChange={handleChangeYearWidth}
            defaultValue={minYearWidth * 3}
          />
        </div>
      </div>
      <div className={styles.content}
        ref={contentRef}
        onScroll={(e) => handleScroll(e)}
        >
        <div className={styles.dates} style={{ top: scrollPos.y }}>
          {contentRef.current?.scrollHeight > 0 && dates?.map((d) => (
            <div
              key={d.toString()}
              ref={getMonth(d).isActive ? monthRef : null}
              style={{
                width: (yearWidth / 12),
              }}
              className={getMonth(d).className}>

              {yearWidth === minYearWidth
                ? <>
                {new Date(d).getMonth() === 0 && <p className={styles.year}>{new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(new Date(d))}</p>}
                <p>{new Intl.DateTimeFormat('fr', { month: 'short' }).format(new Date(d))}</p>
              </>
                : <p>{new Intl.DateTimeFormat('fr', { month: 'short', year: yearWidth < 1200 ? '2-digit' : 'numeric' }).format(new Date(d))}</p>
              }
            </div>
          ))}
        </div>
        <div className={styles.timelines} ref={timelineRef}>
          {timelineData?.map((g, i) => <TimelineRow
            key={i}
            steps={g}
            index={i}
            scrollPos={scrollPos}
            contentRef={contentRef}
            width={(yearWidth / 12) * dates?.length}
            />)
          }
        </div>
      </div>

    </div>
  );
}
