import { createStore, combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import treeReducer from '../reducers/treeReducer';
import comparatorReducer from '../reducers/comparatorReducer';
import timelineReducer from '../reducers/timelineReducer';
import geographyReducer from '../reducers/geographyReducer';
import networkReducer from '../reducers/networkReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  comparator: comparatorReducer,
  tree: treeReducer,
  timeline: timelineReducer,
  geography: geographyReducer,
  network: networkReducer,
});

export default createStore(rootReducer);
