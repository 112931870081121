import { format } from 'date-fns';
import React, { useState } from 'react';
import { hexToRgba } from '../../../../utils/utils';
import styles from './Step.module.scss';

export default function Step({ step, top }) {
  const [hover, setHover] = useState(false);
  const { color } = step;
  const initTooltip = {
    left: 0,
    top: 0,
    opacity: 0,
  };
  const [tooltip, setTooltip] = useState(initTooltip);
  return (
    <>
      <div
        className={styles.container}
        style={{ ...step.styles, top: top + 45 }}>
        <div
          className={styles.step}
          onMouseEnter={(e) => {
            setTooltip({
              ...initTooltip, left: e.pageX, top: e.pageY, opacity: 1,
            });
            setHover(true);
          }}
          onMouseLeave={() => {
            setTooltip({
              ...initTooltip, opacity: 0,
            });
            setHover(false);
          }}
          style={{
            backgroundColor: hexToRgba(color, 1),
            ...(hover ? { boxShadow: `0 0 0 3px ${hexToRgba(color, 0.5)}` } : null),
          }}
        >

        </div>
        <div
          className={styles.tooltip}
          style={tooltip}
        >
          <div className={styles.dates}>
            {step.date && <p>{format(new Date(step.date), 'dd/MM/yyyy') }</p>}
            {step.startDate && <p>{format(new Date(step.startDate), 'dd/MM/yyyy') }</p>}
            {step.endDate && <p>{format(new Date(step.endDate), 'dd/MM/yyyy') }</p>}
          </div>
          <p>{step.name ? step.name : 'Lorem ipsum dolor sit'}</p>
        </div>
      </div>
    </>
  );
}
