import React from 'react';
import Step from '../Step/Step';
import styles from './TimelineRow.module.scss';

export default function TimelineRow({
  steps, index, width, scrollPos, contentRef,
}) {
  const size = 45;
  const stepOne = steps[0];
  function scrollToStep() {
    contentRef.current.scrollTo({
      left: stepOne.styles.left,
      behavior: 'smooth',
    });
  }
  return (
    <div className={styles.container}>
      {steps.map((d) => <Step
        key={d.stepId}
        step={d}
        size={size}
        top={index * size}
      />)}
      <div className={styles.line} style={{ top: (index * size) + 65, width }}/>
       <button
        className={`${styles.year} ${scrollPos.x + contentRef.current?.offsetWidth < stepOne.styles.left ? styles.active : ''}`}
        style={{
          top: (index * size) + 65,
          left: scrollPos.x + contentRef.current?.offsetWidth - 70,
        }}
        onClick={() => scrollToStep()}
      >
        {new Date(stepOne.sortDate).getFullYear()}
        {' '}
        {'>'}
      </button>
  </div>
  );
}
