import React, { useRef } from 'react';
import Filters from '../../components/Timeline/Filters/Filters';
import TimelineViz from '../../components/Timeline/Timeline/TimelineViz';
import styles from './Timeline.module.scss';

export default function Timeline() {
  const filtersRef = useRef();
  return (
    <div className={styles.container}>
      <Filters ref={filtersRef}/>
      <TimelineViz
        filters={filtersRef && filtersRef?.current?.filters}
      />
    </div>
  );
}
