import { getData, postData } from './index';

import {
  ERROR_GEOGRAPHY,
  POST_GEOGRAPHY,
  GET_GEOGRAPHY_OPTIONS,
} from './types';

export async function getGeographyOptionsAction(dispatch) {
  const url = '/dataviz/octaviz/geography';
  let options;
  await getData(ERROR_GEOGRAPHY, url, dispatch, true).then((response) => {
    options = [
      {
        value: {
          type: 'companies',
          content: response.companies,
        },
        label: 'Entreprise',
      },
      {
        value: {
          type: 'drugs',
          content: response.drugs,
        },
        label: 'Produits',
      },
      {
        value: {
          type: 'gers',
          content: response.gers,
        },
        label: 'Gers',
      },
      {
        value: {
          type: 'projects',
          content: response.projects,
        },
        label: 'Projets',
      },
      {
        value: {
          type: 'studies',
          content: response.studies,
        },
        label: 'Études',
      },
    ];
  });
  dispatch({
    type: GET_GEOGRAPHY_OPTIONS,
    payload: options,
  });
}

export async function postGeographyAction(dispatch, obj) {
  const url = '/dataviz/octaviz/geography';
  let items;
  await postData(
    POST_GEOGRAPHY, ERROR_GEOGRAPHY, url, dispatch, obj, true,
  ).then((response) => {
    items = response.items;
  });
  dispatch({
    type: POST_GEOGRAPHY,
    payload: items,
  });
}

export async function getGeographyCenterAction(dispatch) {
  console.log(dispatch);
  // const url = '/dataviz/octaviz/compare';
  // let compareList;
  // await getData(ERROR_GEOGRAPHY, url, dispatch, true).then((response) => {
  //   compareList = response.objects;
  // });
  // dispatch({
  //   type: GET_GEOGRAPHY,
  //   payload: compareList,
  // });
}
