import React from 'react';
import './tooltip.scss';

export default function Tooltip({ data, type }) {
  return (
    <div
      className={`tooltip ${type}`}
      style={{
        left: data.x,
        top: data.y,
        border: `2px solid ${data.color ? data.color : 'transparent' }`,
        opacity: data.color ? 1 : 0,
      }}>
        {data && data.type === 'study' && type === 'geography'
        && <p>
          <span className="code">{data.name}</span><br/>
          {data.phase}
        </p>
      }
      {data && data.type === 'center' && type === 'geography'
        && <p>
          <span className="code">{data.name}</span><br/>
          <span>{data.studies.length} étude{data.studies.length !== 1 && 's'}</span><br/>
        </p>
      }
      {data && data.type === 'city' && type === 'geography'
        && <p>
          <span className="code">{data.name}</span><br/>
          <span>{data.centers.length} centre{data.centers.length !== 1 && 's'}</span><br/>
        </p>
      }
    </div>
  );
}
