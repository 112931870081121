import {
  ERROR_NETWORK,
  GET_NETWORK,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  data: null,
};

export default function networkReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NETWORK:
      return { ...state, data: action.payload };
    case ERROR_NETWORK:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
