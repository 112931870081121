import { format } from 'date-fns';
import slugify from 'slugify';
import { BsCalendar4 } from 'react-icons/bs';
import { BiLink } from 'react-icons/bi';
import React, {
  useLayoutEffect, useRef,
} from 'react';
import styles from './ComparatorProperty.module.scss';

const Value = ({ type, value }) => {
  if (!value) return null;
  switch (type) {
    case 'string':
      return (
        <div className={styles[type]}>
          <p>{value}</p>
        </div>
      );
    case 'array':
      return (
            <div className={styles[type]}>
              {value.map((v) => <p key={v}>{v}</p>)}
            </div>
      );
    case 'url':
      return (
          <div className={styles[type]}>
            <a href={value} target='_blank' rel="noreferrer"><BiLink size={18}/>{value}</a>
          </div>
      );
    case 'date':
      return (
          <div className={styles[type]}>
            <BsCalendar4 size={22} /><p>{format(new Date(value), 'dd/MM/yyyy')}</p>
          </div>
      );
    case 'number':
      return (
          <div className={styles[type]}>
            <p>{value}</p>
          </div>
      );
    default:
      return null;
  }
};

export default function ComparatorProperty({ property }) {
  const propertyRef = useRef();
  const className = slugify(property.label.toLowerCase());
  console.log(className);
  useLayoutEffect(() => {
    const sameProperties = document.getElementsByClassName(className);
    if (sameProperties?.length > 0 && propertyRef?.current) {
      let maxHeight = 0;
      Array.from(sameProperties).forEach((el) => {
        if (el.offsetHeight >= maxHeight) {
          maxHeight = el.offsetHeight;
        }
      });
      propertyRef.current.style.height = `${maxHeight}px`;
    }
  }, [property]);

  return (
    <div ref={propertyRef} className={`${styles.container} ${className}`}>
      <div>
        <label>{property.label}</label>
        <Value
          type={property.type}
          value={property.value}
        />
      </div>
    </div>
  );
}
