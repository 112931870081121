import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../../actions/authActions';
import styles from './Auth.module.scss';
import { TOOL_ID } from '../../actions';

export default function SwitchIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth, error } = useSelector((state) => state.auth);
  const { query } = useSelector((state) => state.comparator);
  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  const clearError = useCallback(() => {
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getUser();
    return () => {
      clearError();
    };
  }, [getUser, clearError]);

  useEffect(() => {
    if (userAuth?.tools?.find((p) => p.tool === TOOL_ID && p.role === 'Admin')) {
      navigate(`/comparator${query}`);
    }
  }, [userAuth]);

  useEffect(() => {
    if (error === 401) {
      navigate('/login');
    }
  }, [error]);

  return (
    <div className={styles.container}>
    </div>
  );
}
