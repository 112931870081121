import {
  ERROR_GEOGRAPHY,
  POST_GEOGRAPHY,
  MESSAGE_GEOGRAPHY,
  SET_GEOGRAPHY_PANEL,
  SET_GEOGRAPHY_FILTERS,
  SET_GEOGRAPHY_QUERY,
  GET_GEOGRAPHY_OPTIONS,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  geography: null,
  panel: null,
  query: '?',
  options: null,
  filters: {
    drugs: [],
    companies: [],
  },
};

export default function geographyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GEOGRAPHY_OPTIONS:
      return { ...state, options: action.payload };
    case SET_GEOGRAPHY_PANEL:
      return { ...state, panel: action.payload };
    case SET_GEOGRAPHY_FILTERS:
      return { ...state, filters: action.payload };
    case SET_GEOGRAPHY_QUERY:
      return { ...state, query: action.payload };
    case POST_GEOGRAPHY:
      return { ...state, geography: action.payload };
    case ERROR_GEOGRAPHY:
      return { ...state, error: action.payload };
    case MESSAGE_GEOGRAPHY:
      return { ...state, message: action.payload };
    default:
      return { ...state };
  }
}
