import { getData, postData } from './index';

import {
  ERROR_TIMELINE,
  GET_TIMELINE_TYPES,
  POST_TIMELINE,
} from './types';

export async function getTimelineTypesAction(dispatch) {
  const url = '/dataviz/octaviz/timeline';
  let timelineList;
  await getData(ERROR_TIMELINE, url, dispatch, true).then((response) => {
    timelineList = response;
  });
  timelineList.objects = timelineList.objects.map((item) => {
    const obj = { ...item };
    if (obj.value === 'study') {
      obj.color = '#4F21B1';
    }
    if (obj.value === 'variation') {
      obj.color = '#40B7A1';
    }
    if (obj.value === 'training') {
      obj.color = '#D06666';
    }
    if (obj.value === 'project') {
      obj.color = '#04c2f2';
    }
    if (obj.value === 'congress') {
      obj.color = '#007CF7';
    }
    return obj;
  });
  dispatch({
    type: GET_TIMELINE_TYPES,
    payload: timelineList,
  });
}

export async function postTimelineAction(dispatch, obj) {
  const url = '/dataviz/octaviz/timeline';
  let items;
  await postData(
    POST_TIMELINE, ERROR_TIMELINE, url, dispatch, obj, true,
  ).then((response) => {
    items = response.items;
  });
  dispatch({
    type: POST_TIMELINE,
    payload: items,
  });
}
