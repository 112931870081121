import React, {
  useEffect, useCallback, useImperativeHandle, forwardRef, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RiCheckboxFill, RiCheckboxBlankFill } from 'react-icons/ri';
import { IoChevronUpOutline } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import { getTimelineTypesAction, postTimelineAction } from '../../../actions/timelineActions';
import { SET_TIMELINE_QUERY } from '../../../actions/types';
import { StyledSelect } from '../../../lib/HooksFormFields';
import { hexToRgba } from '../../../utils/utils';

import Loader from '../../../lib/Loader/Loader';
import styles from './Filters.module.scss';

// eslint-disable-next-line no-empty-pattern
const Filters = ({}, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { query, timelineTypes, timelineFiltrers } = useSelector((state) => state.timeline);
  const [variationsIsOpen, setVariationsIsOpen] = useState(false);
  const [addFilters, setAddFilters] = useState(false);
  const {
    control,
    reset,
    watch,
  } = useForm();
  const newFilter = watch('filters')?.value;

  const getLists = useCallback(() => {
    getTimelineTypesAction(dispatch);
  }, [dispatch]);

  function getOptionLabel(opt) {
    return `${opt.name} ${opt?.location?.city ? opt?.location?.city : ''}`;
  }

  function getSearch() {
    const result = {
      types: [],
      variationsTypes: [],
      filters: [],
    };
    const entries = searchParams.entries();
    // eslint-disable-next-line
    for(const [key, value] of entries) {
      if (key === 'types' || key === 'variationsTypes') {
        result[key] = [...result[key], value];
      } else if (key === 'filters') {
        const obj = new URLSearchParams(value);
        const params = Object.fromEntries(obj);
        result[key] = [...result[key], params];
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  const filters = getSearch();

  function handleChangeQuery(key, value) {
    const updatedQuery = { ...filters };
    console.log(key, value);

    if (updatedQuery[key].find((v) => v === value)) {
      if (value === 'variation') {
        updatedQuery.variationsTypes = [];
      }
      if (key === 'filters') {
        updatedQuery[key] = updatedQuery[key]
          .filter((v) => v !== value || v?._id !== value?._id)
          .map((s) => new URLSearchParams(s).toString());
      }
      if (key === 'types' || key === 'variationsTypes') {
        updatedQuery[key] = updatedQuery[key].filter((v) => v !== value);
      }
    } else {
      if (value === 'variation') {
        updatedQuery.variationsTypes = ['Majeur', 'Mineur'];
      }
      if (key === 'filters') {
        const string = new URLSearchParams(value).toString();
        updatedQuery[key] = [
          ...updatedQuery[key].map((s) => new URLSearchParams(s).toString()),
          string,
        ];
      }
      if (key === 'types' || key === 'variationsTypes') {
        updatedQuery[key] = [...updatedQuery[key], value];
      }
    }
    setSearchParams(updatedQuery);
  }

  useImperativeHandle(ref, () => ({
    handleChangeQuery: (key, value) => handleChangeQuery(key, value),
    filters,
  }));

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (filters?.types.length === 0 && timelineTypes) {
      setSearchParams({
        types: timelineTypes?.map((f) => f.value),
        variationsTypes: ['Majeur', 'Mineur'],
        filters: [],
      });
    }
  }, [timelineTypes]);

  useEffect(() => {
    if (filters?.types?.length > 0) {
      postTimelineAction(dispatch, filters);
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.pathname === '/timeline' && location?.search && location?.search !== query) {
      dispatch({
        type: SET_TIMELINE_QUERY,
        payload: location.search,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (newFilter) {
      handleChangeQuery('filters', newFilter);
      setAddFilters(false);
      reset();
    }
  }, [newFilter]);

  return (
    <>
      <div className={styles.container}>
        {timelineTypes?.length
          ? <ul className={styles.types}>
            {timelineTypes?.map((t) => <li key={t.value} >
              {t.value !== 'variation'
                ? <button
                  className={styles['type-item']}
                  style={{
                    backgroundColor: filters?.types?.find(
                      (f) => f === t.value,
                    ) ? t.color : hexToRgba(t.color, 0.5),
                  }}
                  onClick={() => handleChangeQuery('types', t.value)}
                >
                  {t.label}
                </button>
                : <div
                  className={styles['type-item']}
                  style={{
                    backgroundColor: filters?.types?.find(
                      (f) => f === t.value,
                    ) ? t.color : hexToRgba(t.color, 0.5),
                  }}>
                  <button
                    onClick={() => handleChangeQuery('types', t.value)}
                  >
                    {t.label}
                  </button>
                  {filters?.types.find((f) => f === t.value)
                    && <div className={styles.check}>
                      <button
                        className={styles.toggle}
                        onClick={() => setVariationsIsOpen(!variationsIsOpen)}
                      >
                        <div className={variationsIsOpen ? styles.active : ''}>
                          <IoChevronUpOutline />
                        </div>
                      </button>
                      {variationsIsOpen && t.options.map((opt) => <button
                        key={opt.value}
                        onClick={() => handleChangeQuery('variationsTypes', opt.value)}
                      >
                        {filters?.variationsTypes?.find((f) => f === opt.value)
                          ? <RiCheckboxFill />
                          : <RiCheckboxBlankFill />
                        }
                        <p>{opt.label}</p>
                      </button>)}
                    </div>
                  }
              </div>
              }
            </li>)}
          </ul>
          : <div>
            <Loader />
          </div>
        }
      </div>
      <div className={`${styles.filters} ${addFilters ? styles.active : ''}`}>
        {filters?.filters?.map((f) => (
          <button
            key={f._id}
            className={styles.add}
            onClick={() => handleChangeQuery('filters', f)}
          >
            {f.name} <MdClose />
          </button>
        ))}
        {!addFilters
          && <button className={styles.add}
            onClick={() => setAddFilters(true)}
          >
            Ajouter un filtre
          </button>
        }
        {addFilters && timelineFiltrers
          && <>
            <div className={styles.search}>
              <StyledSelect
                name={'selected'}
                control={control}
                value={watch('selected')}
                placeholder={'Rechercher'}
                isSearchable={true}
                options={timelineFiltrers.map((opt) => ({ value: opt, label: opt.name }))}
              />
            </div>
            {watch('selected')?.value?.content
              && <div className={styles.search}>
                <StyledSelect
                  name={'filters'}
                  control={control}
                  value={watch('filters')}
                  placeholder={'Rechercher'}
                  isSearchable={true}
                  options={watch('selected')?.value?.content.map((opt) => ({ value: { _id: opt._id, name: getOptionLabel(opt), type: watch('selected')?.value?.type }, label: getOptionLabel(opt) }))}
                />
              </div>
            }
          </>
        }
      </div>
    </>
  );
};

export default forwardRef(Filters);
