import React from 'react';
import styles from './Loader.module.scss';

export default function Loader({ color = '#503CC8' }) {
  return (
    <div className={styles.loader}>
      <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30px"
        height="20px"
        enableBackground="new 0 0 0 0" xmlSpace="preserve">
        <circle fill={color} stroke="none" cx="4" cy="10" r="4">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"/>
        </circle>
        <circle fill={color} stroke="none" cx="14" cy="10" r="4">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"/>
        </circle>
        <circle fill={color} stroke="none" cx="24" cy="10" r="4">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"/>
        </circle>
      </svg>
    </div>
  );
}
