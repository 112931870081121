import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createFilter } from 'react-select';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { StyledSelect } from '../../../lib/HooksFormFields';
import ComparatorItem from '../ComparatorItem/ComparatorItem';
import styles from './ComparatorList.module.scss';

export default function ComparatorList({
  handleChangeQuery,
  filters,
}) {
  const { comparatorList, comparatorOptions, changeAt } = useSelector((state) => state.comparator);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [prevListSize, setPrevListSize] = useState(0);
  const listRef = useRef();
  const {
    control,
    watch,
    setValue,
  } = useForm();
  const selected = watch('items');

  function addToggle() {
    setValue('items', null);
    setAddIsOpen(!addIsOpen);
  }

  function handleScrollX() {
    listRef.current.scrollTo({
      top: 0,
      left: listRef.current.scrollWidth - listRef.current.clientWidth,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    if (selected?.value) {
      handleChangeQuery('items', selected.value);
      setValue('items', null);
      setAddIsOpen(!addIsOpen);
    }
  }, [selected?.value]);

  useEffect(() => {
    if (comparatorList?.length > prevListSize) {
      handleScrollX();
    }
    setPrevListSize(comparatorList?.length);
  }, [comparatorList?.length]);

  return (
    <div
      ref={listRef}
      className={styles.container}>
      {comparatorList?.map((d) => <ComparatorItem
          key={d._id}
          item={d}
          properties={comparatorOptions?.properties
            ?.filter((p) => filters?.properties.find((f) => f === p.value))}
          handleChangeQuery={handleChangeQuery}
          changeAt={changeAt}
        />)}
      <div className={styles.add}>
        <div className={styles['select-container']}
          style={{
            width: addIsOpen ? 265 : 0,
            opacity: addIsOpen ? 1 : 0,
            pointerEvents: addIsOpen ? 'all' : 'none',
          }}
          >
          <div className={styles.select}>
            <StyledSelect
              name={'items'}
              control={control}
              value={selected}
              placeholder={'Rechercher'}
              filterOption={createFilter({ ignoreAccents: false })}
              isSearchable={true}
              customMenuList={true}
              options={comparatorOptions?.items.filter(
                (item) => !filters?.items?.find((s) => s === item.value),
              )}
            />
          </div>
        </div>
        <button
          onMouseOver={(e) => handleScrollX(e)}
          onClick={() => addToggle()}
        >
          <FiPlus />
        </button>
      </div>
    </div>
  );
}
