import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import Loader from '../../../lib/Loader/Loader';
import { truncate } from '../../../utils/utils';
import ComparatorProperty from '../ComparatorProperty/ComparatorProperty';
import styles from './ComparatorItem.module.scss';

export default function ComparatorItem({
  item,
  properties,
  handleChangeQuery,
  changeAt,
}) {
  const [itemProperties, setItemPropreties] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const contentRef = useRef();

  useEffect(() => {
    if (item && properties) {
      const values = [];
      properties.forEach((p) => {
        let value = item[p?.value];
        const nested = p?.value?.split('.');
        if (nested?.length > 1) {
          let nestedValue;
          for (let i = 0; i < nested.length - 1; i++) {
            nestedValue = item[nested[i]];
          }
          if (nestedValue && nestedValue[nested[nested.length - 1]]) {
            value = nestedValue[nested[nested.length - 1]];
          }
        }
        values.push({
          ...p,
          key: `${p.label}-${item.name}-${changeAt}`,
          value,
        });
      });
      if (values.length > 0) {
        setItemPropreties(values);
      }
    }
  }, [item, properties, changeAt]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{truncate(item?.name, 45)}</h2>
        <button onClick={() => handleChangeQuery('items', item._id)}>
          <MdClose />
        </button>
      </div>
      <div
        ref={contentRef}
        className={`${styles.content} ${isUpdated}`}>
        {itemProperties?.map((p) => <ComparatorProperty
          key={p.key}
          property={p}
          changeAt={changeAt}
          setIsUpdated={() => setIsUpdated(!isUpdated)}
        />)}
        {!itemProperties && properties
          && <div className={styles['no-data']}>
            <p>Pas de données disponibles</p>
          </div>
        }
        {!itemProperties && !properties
          && <div className={styles['no-data']}>
            <Loader />
          </div>
        }
      </div>
    </div>
  );
}
