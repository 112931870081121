import React, {
  useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSearchParams, useLocation } from 'react-router-dom';

import { MdClose } from 'react-icons/md';
import { SET_GEOGRAPHY_QUERY } from '../../../actions/types';
import { StyledSelect } from '../../../lib/HooksFormFields';

import styles from './Filters.module.scss';
import { postGeographyAction } from '../../../actions/geographyActions';

const Filters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { query, options } = useSelector((state) => state.geography);
  const [addFilters, setAddFilters] = useState(false);
  const {
    control,
    reset,
    watch,
  } = useForm({});
  const newFilter = watch('filters')?.value;

  const defaultFilters = {
    filters: [],
  };

  function getSearch() {
    const result = defaultFilters;
    const entries = searchParams.entries();
    // eslint-disable-next-line
    for(const [key, value] of entries) {
      if (key === 'filters') {
        const obj = new URLSearchParams(value);
        const params = Object.fromEntries(obj);
        result[key] = [...result[key], params];
      }
    }
    return result;
  }

  const filters = getSearch();

  function handleChangeQuery(key, value) {
    const updatedQuery = { ...filters };
    if (key === 'filters') {
      if (updatedQuery[key].find((v) => v === value || v?._id === value?._id)) {
        if (key === 'filters') {
          updatedQuery[key] = updatedQuery[key]
            .filter((v) => v !== value || v?._id !== value?._id)
            .map((s) => new URLSearchParams(s).toString());
        } else {
          updatedQuery[key] = updatedQuery[key].filter((v) => v !== value || v?._id !== value?._id);
        }
      } else if (key === 'filters') {
        const string = new URLSearchParams(value).toString();

        updatedQuery[key] = [
          ...updatedQuery[key].map((s) => new URLSearchParams(s).toString()),
          string,
        ];
      } else {
        updatedQuery[key] = [...updatedQuery[key], value];
      }
    }
    setSearchParams(updatedQuery);
  }

  useEffect(() => {
    if (filters.filters) {
      const data = {
        drugs: filters?.filters?.filter((f) => f.type === 'drugs').map((f) => f._id),
        companies: filters?.filters?.filter((f) => f.type === 'companies').map((f) => f._id),
        gers: filters?.filters?.filter((f) => f.type === 'gers').map((f) => f._id),
        projects: filters?.filters?.filter((f) => f.type === 'projects').map((f) => f._id),
        studies: filters?.filters?.filter((f) => f.type === 'studies').map((f) => f._id),
      };
      postGeographyAction(dispatch, data);
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.pathname === '/geography' && location?.search && location?.search !== query) {
      dispatch({
        type: SET_GEOGRAPHY_QUERY,
        payload: location.search,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (newFilter) {
      handleChangeQuery('filters', newFilter);
      setAddFilters(false);
      reset();
    }
  }, [newFilter]);

  return (
    <>
      <div className={`${styles.filters} ${addFilters ? styles.active : ''}`}>
        {filters?.filters?.map((f) => (
          <button
            key={f._id}
            className={styles.add}
            onClick={() => handleChangeQuery('filters', f)}
          >
            {f.name} <MdClose />
          </button>
        ))}
        {!addFilters
          && <button className={styles.add}
            onClick={() => setAddFilters(true)}
          >
            Ajouter un filtre
          </button>
        }
        {addFilters && options
          && <>
            <div className={styles.search}>
              <StyledSelect
                name={'selected'}
                control={control}
                value={watch('selected')}
                placeholder={'Rechercher'}
                isSearchable={true}
                options={options}
              />
            </div>
            {console.log(watch('selected'))}
            {watch('selected')?.value?.content
              && <div className={styles.search}>
                <StyledSelect
                  name={'filters'}
                  control={control}
                  value={watch('filters')}
                  placeholder={'Rechercher'}
                  isSearchable={true}
                  options={watch('selected')?.value?.content.map((opt) => ({ value: { _id: opt._id, name: opt.name, type: watch('selected')?.value?.type }, label: opt.name }))}
                />
              </div>
            }
          </>
        }
      </div>
    </>
  );
};

export default Filters;
