/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import * as d3 from 'd3';

// import { useDispatch } from 'react-redux';
import arrowBack from '../../../assets/images/arrow-back.svg';

import Tooltip from '../../Tooltip/Tooltip';
import styles from './Map.module.scss';

export default function Map({
  data, geojson, mapWidth, mapHeight, mapScale, mapKey, handleChangePanel,
}) {
  // const dispatch = useDispatch();
  const mapRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({ x: -200, y: -200 });

  function drawMap(geojsonData) {
    const tempData = [];
    handleChangePanel();
    data.forEach((d) => {
      const index = tempData.findIndex((c) => c.name === d.city);
      if (index !== -1) {
        tempData[index].centers = [...tempData[index].centers, d];
        tempData[index].size += 1;
      } else {
        tempData.push({
          id: d.city,
          name: d.city,
          size: 1,
          color: '#192243',
          lat: d.lat,
          lon: d.lon,
          type: 'city',
          centers: [d],
        });
      }
    });

    const cityScale = d3.scaleLinear()
      .domain([0, d3.max(tempData, (d) => +d.size)])
      .range([3, 20]);

    const simulation = d3.forceSimulation();
    const centersSimulation = d3.forceSimulation();

    setTooltipData({ x: mapWidth / 2, y: mapHeight / 2 });

    const height = mapHeight;
    const width = mapWidth;
    const transform = { k: 1, x: 0, y: 0 };

    // var closeNode;
    // var nodePos = [width/2, height/2];
    let selectedCity = null;
    let selectedCenter = null;
    let selectedService = null;

    if (d3.select(`#svg-${mapKey}`)) {
      d3.select(`#svg-${mapKey}`).remove();
    }

    if (d3.select(`#${mapKey}`).select('#cities')) {
      d3.select(`#${mapKey}`).select('#cities').remove();
    }

    if (d3.select(`#${mapKey}`).select('#city')) {
      d3.select(`#${mapKey}`).select('#city').remove();
    }

    if (d3.select(`#${mapKey}`).select('#center')) {
      d3.select(`#${mapKey}`).select('#center').remove();
    }

    const center = d3.geoCentroid(geojsonData);
    let centroid = center;
    const scale = mapScale;
    const offset = [width / 2, height / 2];

    const projection = d3.geoMercator()
      .scale(scale)
      .center(center)
      .translate(offset);

    const geoGenerator = d3.geoPath()
      .projection(projection);

    const svg = d3.select(`#${mapKey}`)
      .append('svg')
      .attr('id', `svg-${mapKey}`)
      .attr('width', `${width }px`)
      .attr('height', `${height }px`);

    function clickedCity(e, d) {
      let x; let y; let
        k;
      d3.select('#city').remove();
      setTooltipData({ x: mapWidth / 2, y: mapHeight / 2 });
      if (d && d.id) {
        centroid = projection([d.lon, d.lat]);
        selectedCity = d;

        x = centroid[0];
        y = centroid[1];
        k = 5;
      } else {
        x = width / 2;
        y = height / 2;
        k = 1;
        selectedCity = null;
      }
      handleChangePanel();
      d3.selectAll('circle')
        .transition()
        .duration(750)
        .attr('style', !selectedCity ? '' : 'pointer-events: none;')
        .attr('fill-opacity', !selectedCity ? 0.4 : 0);

      if (selectedCity) {
        d3.select('#city').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr('id', 'city')
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class', `${styles.city}`)
          .append('p')
          .attr('class', `${styles.label}`)
          .on('click', (e) => clickedCity(e, null))
          .text(`${selectedCity.name}`);

        d3.select('#city')
          .append('div')
          .attr('class', `${styles.back}`)
          .on('click', (e) => clickedCity(e, null))
          .text('Retour')
          .append('img')
          .attr('src', arrowBack);

        const centers = [...selectedCity.centers];
        centersSimulation.nodes(centers, (d) => d.id)
          .force('charge', d3.forceManyBody().strength(5))
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius(45))
          .tick(300);

        d3.select('#city').selectAll(`.${styles.center}`)
          .data(selectedCity.centers, (d) => d.id)
          .enter()
          .append('div')
          .attr('id', (d) => d.id)
          .attr('class', styles.center)
          .style('background-color', (d) => d.color)
          .style('transform', (d) => `translate(${ d.x + 190 }px,${ d.y + 170 }px)`)
          .on('click', clickedCenter)
          .append('p')
          .text((d) => d.name);
      } else {
        d3.select('#city').remove();
        d3.select('#center').remove();
      }

      d3.select('#city')
        .transition()
        .delay(selectedCity ? 250 : 10)
        .duration(selectedCity ? 750 : 10)
        .attr('class', `${styles.city} ${selectedCity ? styles.isActive : ''}`);

      d3.select(`#${mapKey}`).selectAll('g')
        .transition()
        .duration(750)
        .attr('transform', `translate(${ width / 2 },${ height / 2 })scale(${ k })translate(${ -x },${ -y })`);
    }

    function clickedService(e, d) {
      d3.select('#service').remove();
      d3.select('#center').remove();
      d3.select('#city').remove();
      console.log('service', d);
      if (d && d.id) {
        centroid = projection([selectedCity.lon, selectedCity.lat]);
        selectedService = d;
      } else {
        selectedService = null;
      }

      if (selectedService) {
        handleChangePanel({
          ...selectedService,
          center: selectedCenter.name,
          city: selectedCenter.city,
        });
        d3.select('#center').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr('id', 'service')
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class', `${styles['selected-service']}`)
          .append('p')
          .attr('class', `${styles.label}`)
          .text(`${selectedService.name}`);

        d3.select('#service')
          .append('div')
          .attr('class', `${styles.back}`)
          .on('click', (e) => {
            clickedCenter(e, selectedCenter);
          })
          .text(`${selectedCenter.name}`)
          .append('img')
          .attr('src', arrowBack);

        const nodes = [...selectedService.nodes];
        centersSimulation.nodes(nodes, (d) => d.type)
          .force('charge', d3.forceManyBody())
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius((d) => d.size / 2 + 10))
          .tick(300);

        d3.select('#service').selectAll(`.${styles.node}`)
          .data(selectedService.nodes, (d) => d.type)
          .enter()
          .append('div')
          .attr('id', (d) => d.type)
          .attr('class', (d) => `${styles.node} ${styles[d.type]}`)
          .style('width', (d) => `${d.size}px`)
          .style('height', (d) => `${d.size}px`)
          .style('border-radius', (d) => `${d.size}px`)
          .style('transform', (d) => `translate(${ d.x + 185 }px,${ d.y + 190 }px)`)
          .on('click', (e, d) => handleChangePanel({
            ...selectedService,
            center: selectedCenter.name,
            city: selectedCenter.city,
            type: d.type,
          }))
          .append('p')
          .style('transform', `translateY(-${25}px)`)
          .text((d) => d.label);
      } else {
        d3.select('#service').remove();
      }

      d3.select('#service')
        .transition()
        .delay(selectedService ? 250 : 10)
        .duration(selectedService ? 750 : 10)
        .attr('class', `${styles['selected-service']} ${selectedService ? styles.isActive : ''}`);
    }

    function clickedCenter(e, d) {
      let x; let y; let
        k;
      d3.select('#center').remove();
      d3.select('#service').remove();
      d3.select('#city').remove();
      if (d && d.id) {
        centroid = projection([selectedCity.lon, selectedCity.lat]);
        selectedCenter = d;
        x = centroid[0];
        y = centroid[1];
        k = 12;
      } else {
        x = width / 2;
        y = height / 2;
        k = 1;
        selectedCenter = null;
      }

      if (selectedCenter) {
        handleChangePanel(selectedCenter);
        d3.select('#service').remove();
        d3.select('#center').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr('id', 'center')
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class', `${styles['selected-center']}`)
          .append('p')
          .attr('class', `${styles.label}`)
          .text(`${selectedCenter.name}`)
          .on('click', () => {
            handleChangePanel(selectedCenter);
          });

        d3.select('#center')
          .append('div')
          .attr('class', `${styles.back}`)
          .on('click', (e) => {
            clickedCenter(e, null);
            clickedCity(e, selectedCity);
          })
          .text(`${selectedCity.name}`)
          .append('img')
          .attr('src', arrowBack);

        const nodes = [...selectedCenter.nodes];
        centersSimulation.nodes(nodes, (d) => d.type)
          .force('charge', d3.forceManyBody())
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius((d) => d.size / 2 + 10))
          .tick(300);

        d3.select('#center').selectAll(`.${styles.node}`)
          .data(selectedCenter.nodes, (d) => d.type)
          .enter()
          .append('div')
          .attr('id', (d) => d.type)
          .attr('class', (d) => `${styles.node} ${styles[d.type]}`)
          .style('width', (d) => `${d.size}px`)
          .style('height', (d) => `${d.size}px`)
          .style('border-radius', (d) => `${d.size}px`)
          .style('transform', (d) => `translate(${ d.x + 185 }px,${ d.y + 190 }px)`)
          .on('click', (e, d) => handleChangePanel({ ...selectedCenter, type: d.type }))
          .append('p')
          .style('transform', `translateY(-${25}px)`)
          .text((d) => d.label);

        const radius = 450 / 2;
        selectedCenter.services = selectedCenter.services.map((n, i) => {
          const s = { ...n };
          const length = selectedCenter.services?.length > 1
            ? selectedCenter.services.length - 1 : selectedCenter.services.length;
          const angle = (i / length) * Math.PI;
          s.x = ((radius * Math.cos(angle)) + (radius)) - 45;
          s.y = ((radius * Math.sin(angle)) + (radius)) - 45;
          return s;
        });

        d3.select('#center').selectAll(`.${styles.service}`)
          .data(selectedCenter.services, (d) => d.id)
          .enter()
          .append('div')
          .attr('id', (d) => d.id)
          .attr('class', styles.service)
          .style('background-color', (d) => d.color)
          .style('transform', (d) => `translate(${ d.x + 5 }px,${ d.y + 5 }px)`)
          .on('click', (e, d) => {
            clickedService(e, d);
          })
          .append('p')
          .text((d) => d.name);
      } else {
        d3.select('#center').remove();
        d3.select('#service').remove();
      }

      d3.select('#center')
        .transition()
        .delay(selectedCity ? 250 : 10)
        .duration(selectedCity ? 750 : 10)
        .attr('class', `${styles['selected-center']} ${selectedCenter ? styles.isActive : ''}`);

      d3.select(`#${mapKey}`).selectAll('g')
        .transition()
        .duration(750)
        .attr('transform', `translate(${ width / 2 },${ height / 2 })scale(${ k })translate(${ -x },${ -y })`);
    }

    svg.append('g')
      .attr('id', 'area')
      .selectAll('path')
      .data(geojsonData.features)
      .enter()
      .append('path')
      .attr('d', (d) => geoGenerator(d))
      .attr('fill', '#E3E3E3')
      .on('click', clickedCity);

    simulation.nodes(tempData)
      .force('charge', d3.forceManyBody().strength(1))
      .force('collision', d3.forceCollide().radius((d) => (cityScale(d.size) + 1) / transform.k))
      .force('x', d3.forceX((d) => {
        const pos = projection([d.lon, d.lat]);
        return pos[0];
      }))
      .force('y', d3.forceY((d) => {
        const pos = projection([d.lon, d.lat]);
        return pos[1];
      }));

    simulation.alpha(1).stop();

    const numberOfTicks = Math.ceil(
      Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()),
    );
    for (let i = 0, n = numberOfTicks; i < n; ++i) {
      simulation.tick();
    }

    svg.append('g')
      .attr('id', 'markers').selectAll('city')
      .data(tempData, (d) => d.id)
      .enter()
      .append('circle')
      .attr('id', (d) => d.id)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => cityScale(d.size))
      .attr('fill', (d) => d.color)
      .attr('fill-opacity', 1)
      .on('mouseover', (e, d) => {
        const city = document.getElementById(d.id).getBoundingClientRect();
        setTooltipData({ ...d, x: city.left - 60, y: city.top - 40 });
        d3.selectAll('circle')
          .transition()
          .duration(250)
          .attr('fill-opacity', (c) => {
            if (c && d && c.id === d.id) {
              return selectedCity ? 0 : 1;
            }
            return selectedCity ? 0 : 0.2;
          });
      })
      .on('mouseout', (e, d) => {
        const city = document.getElementById(d.id).getBoundingClientRect();
        setTooltipData({ x: city.left - 60, y: city.top - 40 });
        d3.selectAll('circle')
          .transition()
          .duration(250)
          .attr('fill-opacity', selectedCity ? 0 : 1);
      })
      .on('click', clickedCity);
  }

  useEffect(() => {
    drawMap(geojson);
    window.addEventListener('resize', drawMap(geojson));
    return () => {
      window.removeEventListener('resize', drawMap(geojson));
    };
  }, [data]);

  return useMemo(() => (
    <div
      ref={mapRef}
      className={styles.map}
      id={mapKey}
      style={{ height: mapHeight, width: mapWidth }}>
      <Tooltip data={tooltipData} type={'geography'}/>
    </div>
  ), [tooltipData, mapHeight, mapWidth, mapKey, data]);
}
