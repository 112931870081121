import React, { useEffect, useState } from 'react';
import { BiLink } from 'react-icons/bi';
import { legend } from '../Network/Network';
import styles from './NetworkPanel.module.scss';

export default function NetworkPanel({
  panel,
  close,
}) {
  const [categories, setCategories] = useState([]);
  function getUrl(node) {
    const admin = process.env.REACT_APP_OCTADMIN_URL;
    const clinocta = process.env.REACT_APP_CLINOCTA_URL;
    switch (node?.category) {
      case 'study':
        return `${clinocta}/edit-study/${node.value}`;
      case 'center':
        return `${admin}/center/${node.value}?form=0`;
      case 'project':
        return `${admin}/project/${node.value}`;
      case 'company':
        return `${admin}/company/${node.value}`;
      case 'drug':
        return `${admin}/product/${node.value}`;
      case 'doctor':
        return `${admin}/user/${node.value}`;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (panel?.nodes) {
      const panelCategories = [];
      legend.forEach((l) => {
        const cat = panel.nodes.filter((n) => n.category === l.category).map((n) => ({
          label: n.label,
          url: getUrl(n),
        }));
        if (cat.length > 0) {
          panelCategories.push({
            label: l.label,
            value: l.category,
            color: l.colors[0],
            category: cat,
          });
        }
      });
      setCategories(panelCategories);
    } else {
      setCategories([]);
    }
  }, [panel?.nodes]);

  return (
    <div className={`${styles.container} ${panel ? styles.active : ''}`}>
      <div className={styles.bar}
        style={{ backgroundColor: panel?.color || 'transparent' }}
      >

      </div>
      <button
        className={styles.close}
        onClick={() => close()}
      >
        Fermer
      </button>
      {panel
      && <>
        <div className={styles.title}>
          <h3>{panel?.label}</h3>
          {getUrl(panel)
            && <div className={styles.link}>
            <BiLink />
            <a href={getUrl(panel)} target="_blank" rel="noreferrer" >Aller voir la page</a>
          </div>}
        </div>
        <div className={styles.categories}>
          {categories?.map((c) => <div key={c.label} className={styles.category}>
            <p>{c.label} <span>{c.value === 'study' ? 'liées' : 'liés'}</span></p>
            <ul>
              {c.category.map(((item) => <li key={item.label}>
                  {item.url && <a href={item.url} style={{ color: c.color }} target="_blank" rel="noreferrer" >{item.label}</a>}
                </li>
              ))}
            </ul>
          </div>)}
        </div>
      </>
      }
    </div>
  );
}
