import { getData } from './index';

import {
  ERROR_NETWORK,
  GET_NETWORK,
} from './types';

export default async function getNetworkAction(dispatch) {
  const url = '/dataviz/octaviz/network';
  let data;
  await getData(ERROR_NETWORK, url, dispatch, true).then((response) => {
    data = response;
  });
  dispatch({
    type: GET_NETWORK,
    payload: {
      ...data,
    },
  });
}
