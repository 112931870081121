import React, {
  useEffect, useCallback, useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { getComparatorListsAction, postComparatorAction, postComparatorOptionsAction } from '../../../actions/comparatorActions';
import { RESET_COMPARATOR_TYPE, SET_COMPARATOR_QUERY } from '../../../actions/types';
import Loader from '../../../lib/Loader/Loader';
import styles from './Filters.module.scss';

// eslint-disable-next-line no-empty-pattern
const Filters = ({}, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { lists, query, comparatorOptions } = useSelector((state) => state.comparator);

  const selectRef = useRef();

  const [selectIsOpen, setSelectIsOpen] = useState(false);

  const getLists = useCallback(() => {
    getComparatorListsAction(dispatch);
    dispatch({
      type: RESET_COMPARATOR_TYPE,
    });
  }, [dispatch]);

  function getSearch() {
    const result = {
      properties: [],
      items: [],
    };
    const entries = searchParams.entries();
    // eslint-disable-next-line
    for(const [key, value] of entries) {
      if (key === 'properties' || key === 'items') {
        result[key] = [...result[key], value];
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  const filters = getSearch();

  function handleChangeQuery(key, value) {
    const updatedQuery = { ...filters };
    if (key === 'properties' || key === 'items') {
      if (updatedQuery[key].find((v) => v === value)) {
        updatedQuery[key] = updatedQuery[key].filter((v) => v !== value);
      } else {
        updatedQuery[key] = [...updatedQuery[key], value];
      }
    }
    setSearchParams(updatedQuery);
  }

  function handleChangeType(type) {
    dispatch({
      type: RESET_COMPARATOR_TYPE,
    });
    setSearchParams({
      type,
      properties: [],
      items: [],
    });
  }

  useImperativeHandle(ref, () => ({
    handleChangeQuery: (key, value) => handleChangeQuery(key, value),
    filters,
  }));

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (filters?.type) {
      postComparatorOptionsAction(dispatch, { type: filters.type });
    }
  }, [filters?.type]);

  useEffect(() => {
    if (filters?.properties.length === 0 && comparatorOptions?.properties) {
      setSearchParams({
        type: filters.type,
        properties: [],
        items: [],
      });
    }
  }, [comparatorOptions]);

  useEffect(() => {
    if (lists?.length && filters?.items?.length > 0) {
      postComparatorAction(dispatch, filters);
    }
  }, [lists, searchParams]);

  useEffect(() => {
    if (location.pathname === '/comparator' && location?.search && location?.search !== query) {
      dispatch({
        type: SET_COMPARATOR_QUERY,
        payload: location.search,
      });
    }
  }, [location.search]);

  return (
    <div className={styles.container}>
      <div className={styles.types}>
        <label>Type d&#39;objet </label>
        <div className={styles.type}>
          <h2>{lists?.find((t) => t.value === filters?.type)?.label}</h2>
          <button
            className={selectIsOpen ? styles.active : ''}
            onClick={() => setSelectIsOpen(!selectIsOpen)}>
            <IoChevronDown />
          </button>
        </div>
        <div
          className={styles.select}
          style={{
            height: selectRef.current && selectIsOpen ? selectRef.current.clientHeight + 20 : 0,
          }}
        >
          <ul ref={selectRef}>
            {lists?.filter((t) => t.value !== filters?.type)?.map((t) => <li key={t.value}>
              <button onClick={() => handleChangeType(t.value)}>
                {t.label}
              </button>
            </li>)}
          </ul>
        </div>
      </div>
      {comparatorOptions?.properties.length
        ? <ul className={styles.properties}>
          {comparatorOptions?.properties.map((t) => <li key={t.value}>
            <button
              className={filters.properties.find((f) => f === t.value) ? styles.active : ''}
              onClick={() => handleChangeQuery('properties', t.value)}>
              {t.label}
            </button>
          </li>)}
        </ul>
        : <div>
          <Loader />
        </div>
      }
    </div>
  );
};

export default forwardRef(Filters);
