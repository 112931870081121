import {
  POST_TIMELINE,
  ERROR_TIMELINE,
  MESSAGE_TIMELINE,
  SET_TIMELINE_QUERY,
  GET_TIMELINE_TYPES,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  timelineList: null,
  timelineTypes: null,
  timelineFiltrers: null,
  query: '?',
};

export default function timelineReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMELINE_QUERY:
      return { ...state, query: action.payload };
    case POST_TIMELINE:
      return { ...state, timelineList: action.payload };
    case GET_TIMELINE_TYPES:
      return {
        ...state,
        timelineTypes: action.payload.objects,
        timelineFiltrers: action.payload.filters,
      };
    case ERROR_TIMELINE:
      return { ...state, error: action.payload };
    case MESSAGE_TIMELINE:
      return { ...state, message: action.payload };
    default:
      return { ...state };
  }
}
