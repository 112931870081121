import {
  GET_TREE_LISTS,
  RESET_TREE_TYPE,
  POST_TREE,
  ERROR_TREE,
  MESSAGE_TREE,
  SET_TREE_QUERY,
  POST_TREE_OPTIONS,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  lists: null,
  tree: null,
  treeOptions: null,
  query: '?type=drug',
};

export default function treeReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_TREE_TYPE:
      return { ...state, tree: null, treeOptions: null };
    case SET_TREE_QUERY:
      return { ...state, query: action.payload };
    case POST_TREE:
      return { ...state, tree: action.payload };
    case POST_TREE_OPTIONS:
      return { ...state, treeOptions: action.payload, tree: null };
    case GET_TREE_LISTS:
      return { ...state, lists: action.payload, tree: null };
    case ERROR_TREE:
      return { ...state, error: action.payload };
    case MESSAGE_TREE:
      return { ...state, message: action.payload };
    default:
      return { ...state };
  }
}
