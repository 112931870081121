/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';
import { useForm } from 'react-hook-form';
import getNetworkAction from '../../../actions/networkActions';
import styles from './Network.module.scss';
import NetworkPanel from '../NetworkPanel/NetworkPanel';
import { StyledSelect } from '../../../lib/HooksFormFields';
import { hexToRgba } from '../../../utils/utils';

export const legend = [
  {
    label: 'Centres',
    category: 'center',
    colors: ['#1F2151', '#313273'],
  },
  {
    label: 'Médecins',
    category: 'doctor',
    colors: ['#5463D2', '#7988f7'],
  },
  {
    label: 'études',
    category: 'study',
    colors: ['#9E7CFF', '#7960CA'],
  },
  {
    label: 'Produits',
    category: 'drug',
    colors: ['#E479A9', '#f59dc4'],
  },
  {
    label: 'Projets',
    category: 'project',
    colors: ['#8AC3E3', '#aedbf5'],
  },
  {
    label: 'Laboratoires',
    category: 'company',
    colors: ['#60dbbd', '#98f5de'],
  },
];

export default function Network() {
  const { data } = useSelector((state) => state.network);
  const {
    control,
    setValue,
    watch,
  } = useForm();

  const selectedNode = watch('selected');

  const dispatch = useDispatch();
  const networkRef = useRef();
  const svgRef = useRef();
  const [filteredData, setFilterdData] = useState();
  const [activePanel, setActivePanel] = useState();
  const [hideLinks, setHideLink] = useState(true);
  const width = networkRef?.current?.getBoundingClientRect()?.width;
  const height = networkRef?.current?.getBoundingClientRect()?.height;

  function drawPath(px1, py1, px2, py2, curveX = null, curveY = null) {
    if (curveX && curveY) {
      const theta = Math.atan2(py2 - py2, px2 - px1) - Math.PI / 2;
      const offset = 30;
      const c1x = curveX + offset * Math.cos(theta);
      const c1y = curveY + offset * Math.sin(theta);
      return `M${ px1 } ${ py1 } Q ${ c1x } ${ c1y } ${ px2 } ${ py2}`;
    }
    return `M${ px1 } ${ py1 } ${ px2 } ${ py2}`;
  }

  function resetOpacities() {
    const updatedData = { ...data };
    updatedData.links.forEach((l) => {
      const link = d3.select(`#link-${l.source.value}-${l.target.value}`);
      link.transition()
        .duration(500)
        .style('display', hideLinks ? 'none' : 'block')
        .attr('stroke-opacity', 0.6);
      return null;
    });
    updatedData.nodes.forEach((n) => {
      const node = d3.select(`#node-${n.value}`);
      node.transition()
        .duration(500)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)
        .attr('opacity', 1);
      return null;
    });
  }

  const handleHideLinks = () => {
    const svg = d3.select(svgRef.current);
    const path = svg.selectAll('path');
    path.transition()
      .duration(500)
      .style('display', !hideLinks ? 'none' : 'block');

    setHideLink(!hideLinks);
  };

  const handleLabel = (d, isOver) => {
    const text = d3.select(`#label-${d.value}`);
    text.transition()
      .duration(500)
      .attr('opacity', isOver ? 1 : 0);
  };

  async function selectNode(id) {
    const updatedData = { ...data };
    const fiteredLinks = [];
    const fiteredNodes = [];
    const path = d3.selectAll('path');
    path.transition()
      .duration(500)
      .style('display', hideLinks ? 'none' : 'block')
      .attr('stroke-opacity', 0);

    const allText = d3.selectAll('text');
    allText.transition()
      .duration(500)
      .attr('opacity', 0);

    const text = d3.select(`#label-${id}`);
    text.transition()
      .duration(500)
      .attr('opacity', 1);

    updatedData.links.forEach((l) => {
      if (l.source.value === id || l.target.value === id) {
        const linkFound = d3.select(`#link-${l.source.value}-${l.target.value}`);
        fiteredLinks.push(l);
        linkFound.transition()
          .duration(500)
          .style('display', 'block')
          .attr('stroke-opacity', 0.6);
      }
    });
    updatedData.nodes.forEach((n) => {
      const foundLink = fiteredLinks.find(
        (l) => n.value === l.target.value || n.value === l.source.value,
      );
      if (foundLink) {
        if (n.value !== id) {
          fiteredNodes.push(n);
        }
        const nodeFound = d3.select(`#node-${n.value}`);
        nodeFound.transition()
          .duration(500)
          .attr('stroke', '#fff')
          .attr('stroke-width', 1)
          .attr('opacity', 1);
      } else {
        const nodeFound = d3.select(`#node-${n.value}`);
        nodeFound.transition()
          .duration(500)
          .attr('stroke', '#fff')
          .attr('stroke-width', 1)
          .attr('opacity', 0.2);
      }
    });

    const activeNode = updatedData.nodes.find((d) => d.value === id);
    const color = legend.find((d) => d.category === activeNode.category)?.colors[0];

    if (color) {
      const node = d3.select(`#node-${id}`);
      node.transition()
        .duration(500)
        .attr('stroke', () => hexToRgba(color, 0.3))
        .attr('stroke-width', 8)
        .attr('opacity', 1);

      setActivePanel({
        ...activeNode,
        color,
        nodes: fiteredNodes,
      });
    }
  }

  function closePanel() {
    setActivePanel();
    resetOpacities();
    setValue('selected', null);
  }

  useEffect(() => {
    getNetworkAction(dispatch);
  }, []);

  useEffect(() => {
    if (data) {
      setFilterdData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    if (selectedNode?.value) {
      selectNode(selectedNode.value);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (filteredData?.nodes) {
      const svg = d3.select(svgRef.current);
      svg.selectAll('g').remove();
      const g = svg.append('g')
        .attr('id', 'group');

      const links = [...filteredData.links];
      const nodes = [...filteredData.nodes];
      const extent = d3.extent(nodes.map(((n) => n.size)));
      const scale = d3.scaleLinear()
        .domain(extent)
        .range([4, 50]);
      // Define the force simulation
      const simulation = d3.forceSimulation(nodes)
        .force('center', d3.forceCenter(width / 2, (height - 80) / 2))
        .force('charge', d3.forceManyBody().strength((d) => {
          const link = links.find((l) => l.source === d.value || l.target === d.value);
          if (!link) {
            return -5;
          }
          return -70;
        }))
        // add some collision detection so they don't overlap
        .force('collide', d3.forceCollide().radius((d) => {
          if (d.size > 6) return scale(d.size) + 30;
          return scale(d.size) + 12;
        }))
        .force('link', d3.forceLink(links).id((d) => d.value));

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      // Create the links
      const link = g.append('g')
        .attr('stroke', '#999')
        .selectAll('path')
        .data(links)
        .join('path')
        .attr('id', (d) => `link-${d.source.value}-${d.target.value}`)
        .style('display', hideLinks ? 'none' : 'block')
        .attr('stroke-width', (d) => Math.sqrt(d.size))
        .attr('stroke-opacity', 0.6)
        .attr('fill', 'none');

      // Create the nodes
      const node = g.append('g')
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)
        .selectAll('circle')
        .data(nodes, (d) => d.value)
        .join('circle')
        .attr('id', (d) => `node-${d.value}`)
        .attr('r', (d) => scale(d.size))
        .attr('fill', (d) => `url('#${d.category}')`)
        .on('click', (e, d) => setValue('selected', { value: d.value, label: d.label }))
        .on('mouseover', (e, d) => handleLabel(d, true))
        .on('mouseout', (e, d) => {
          handleLabel(d, false);
        })
        .call(
          d3.drag()
            .on('start', (e, d) => dragstarted(e, d)) // start - after a new pointer becomes active (on mousedown or touchstart).
            .on('drag', (e, d) => dragged(e, d)) // drag - after an active pointer moves (on mousemove or touchmove).
            .on('end', (e, d) => dragended(e, d)), // end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
        );

      const label = g.append('g')
        .selectAll('text')
        .data(nodes)
        .enter()
        .append('text')
        .style('pointer-events', 'none')
        .attr('filter', 'url(#solid)')
        .attr('id', (d) => `label-${d.value}`)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('font-size', 13)
        .attr('opacity', 0)
        .text((d) => d.label);
      // Update the simulation on each tick

      simulation.on('tick', () => {
        link.attr('d', (d) => {
          const sourceX = d.source.x;
          const sourceY = d.source.y;
          const targetX = d.target.x;
          const targetY = d.target.y;

          const curvePointX = sourceX - ((sourceX - targetX) / 2);
          const curvePointY = sourceY - ((sourceY - targetY) / 2);
          const path = drawPath(sourceX, sourceY, targetX, targetY, curvePointX, curvePointY);
          return path;
        });
        node
          .attr('cx', (d) => d.x)
          .attr('cy', (d) => d.y);

        label.attr('x', (d) => d.x)
          .attr('y', (d) => d.y - (scale(d.size) + 12));
      });

      const zoom = d3.zoom()
        .scaleExtent([0.5, 4]) // Limit the scale factor
        .on('zoom', (e) => {
          const { transform } = e;
          g.attr('transform', `translate(${ transform.x },${ transform.y }) scale(${ transform.k })`);
        });

      svg.call(zoom);
    }
  }, [filteredData?.nodes, filteredData?.links, width, height]);

  return (
    <>
      <div className={styles.legend}>
        {legend.map((l) => <div
            key={l.category}
            className={styles.item}
            style={{ backgroundColor: l.colors[0] }}
            >
            <p>{l.label}</p>
          </div>)}
        <button
          onClick={() => handleHideLinks()}
          className={styles.item}
        >
          <p>{hideLinks ? 'Afficher les liens' : 'Cacher les liens'}</p>
        </button>
      </div>
      <div className={styles.select}>
        <StyledSelect
          name={'selected'}
          control={control}
          value={filteredData?.nodes?.find((item) => item.value === selectedNode?.value)}
          placeholder={'Rechercher'}
          isSearchable={true}
          customMenuList={true}
          options={filteredData?.nodes}
        />
      </div>
      <div
        className={`${styles.container} ${activePanel ? styles.panel : ''}`}
        ref={networkRef}
      >
        <svg ref={svgRef} width={width} height={height}>
        <defs>
          <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
            <feFlood floodColor="#FAFBFB" result="bg" />
            <feMerge>
              <feMergeNode in="bg"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          {legend.map((l) => (
            <linearGradient key={l.category} id={l.category} gradientTransform="rotate(90)">
              <stop offset="20%" stopColor={l.colors[1]}/>
              <stop offset="95%" stopColor={l.colors[0]} />
            </linearGradient>
          ))}
        </defs>

        </svg>
      </div>
      <NetworkPanel
        panel={activePanel}
        close={() => closePanel()}
      />
    </>
  );
}
